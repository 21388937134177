<template>
    <div class="box" :class="item.check" style="width: 100%" @click.once="toggleExpand">
        <article class="media">
            <figure class="media-left">
                <p class="image" :class="imageSize">
                    <img :src="item.image_url" alt="" @click="showGallery">
                </p>
            </figure>

            <div class="media-content">
                <div class="content">
                    <div class="columns">
                        <div class="column">
                            <strong>{{ item.product_code }}</strong>
                            <strong class="pull-right" style="color: red" v-if="item.is_bundle">ARTICOLO
                                BUNDLE!</strong>
                        </div>

                        <div v-if="item.is_customized" class="column wrap-box is-paddingless">
                            <strong>P</strong>
                        </div>

                        <div v-if="item.is_gift" class="column">
                            <span class="icon is-medium" v-if="item.gift_type == 'BOX'">
                                <i class="fa fa-gift"></i>
                            </span>
                            <img v-else src="/static/swap-bag.png" style="width: 50px">
                        </div>
                    </div>

                    <strong v-if="item.check !== 'error'">
                        {{ item.qty || 1 }} x
                    </strong>
                    {{ item.item_name }}
                    <span v-if="showItemId">
                        {{ item.item_id }}
                    </span>


                    <div class="is-pulled-right">
                        <span v-if="showReturnAndPrint">
                            <a class="button is-medium" @click="printItemLabel">
                                <span class="icon is-medium">
                                    <i class="fa fa-barcode" title="Stampa etichetta prodotto"></i>
                                </span>
                            </a>
                        </span>

                        <span v-if="showReturnAndPrint">
                            <a class="button is-medium" @click="showReturnModal = true">
                            <span class="icon is-medium ">
                                <i class="fa fa-external-link" title="Reso a fornitore"></i>
                            </span>
                        </a>
                        </span>
                    </div>

                    <div v-if="showFastCheck" style="display: inline-block">
                        <span>Quantità: </span>
                        <input class="input" type="text" v-model.number="quantity">
                    </div>

                    <div v-if="isExpanded">
                        <div class="is-clearfix"></div>
                        <hr>
                        <slot name="additional-info"></slot>
                    </div>
                </div>
            </div>

            <div v-if="(!readonly || isError) && undo" class="media-right">
                <button v-if="!deleteInProgress" class="delete" @click="undoAction(item)"></button>
                <a v-else class="button is-loading deleting-item"></a>
            </div>
        </article>

        <div class="modal" :class="imagesLoaded ? 'is-active' : ''">
            <div class="modal-background" @click="closeGallery"></div>
            <div class="modal-content" style="background-color: transparent; border: none">
                <div v-if="images.item_additionals.length" id="carouselItemImageIndicators" class="carousel slide" data-ride="carousel" data-interval="false">
                    <ol class="carousel-indicators">
                        <li v-for="(image, index) in images['item_additionals']" data-target="#carouselItemImageIndicators" :data-slide-to="index" class="active"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div v-for="(image, index) in images['item_additionals']" class="carousel-item" :class="index == 0 ? 'active' : ''">
                            <img class="d-block w-100" :src="image['thumbs']['512']"/>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselItemImageIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselItemImageIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
                <div v-else-if="images.product_additionals.length" id="carouselProductImageIndicators" class="carousel slide" data-ride="carousel" data-interval="false">
                    <ol class="carousel-indicators">
                        <li v-for="(image, index) in images['product_additionals']" data-target="#carouselProductImageIndicators" :data-slide-to="index" class="active"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div v-for="(image, index) in images['product_additionals']" class="carousel-item" :class="index == 0 ? 'active' : ''">
                            <img class="d-block w-100" :src="image['thumbs']['512']"/>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselProductImageIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselProductImageIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
            <button class="modal-close" @click="closeGallery"></button>
        </div>

        <div class="modal" :class="showReturnModal ? 'is-active' : ''">
            <div class="modal-background"></div>

            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Conferma reso per {{ item.product_code }}</p>
                    <button class="delete" @click="closeReturnModal" :disabled="executingReturnOrder"></button>
                </header>
                <div v-if="!showReturnMessageNotification">
                    <form @submit.prevent="confirmReturnItem">
                        <section class="modal-card-body">
                            <b>{{ item.product_name }}</b> <span>{{ item.product_description }} - {{ item.item_color }} (Stock: <b>{{
                                item.in_stock_quantity
                            }}</b>)</span>
                            <hr>
                            <div class="field">
                                <label :for="'returnItemReason' + item.item_id" class="label">Motivazione:</label>
                                <p class="control">
                                    <textarea :id="'returnItemReason' + item.item_id" class="textarea" v-model="returnItemReason"
                                              required></textarea>
                                    <span v-text="55 - returnItemReason.length" :class="OverFlowTextReturn"></span>
                                </p>
                            </div>

                        </section>
                        <footer class="modal-card-foot" style="justify-content: space-between;">
                            <button type="button" class="button" @click="closeReturnModal"
                                    :disabled="executingReturnOrder">Annulla
                            </button>
                            <button type="submit" class="button is-success"
                                    :class="executingReturnOrder ? 'is-loading is-pulled-right' : ''"
                                    :disabled="returnItemReason.length === 0">Conferma
                            </button>
                        </footer>
                    </form>
                </div>

                <div v-else>
                    <section class="modal-card-body">
                        <form @submit.prevent="confirmPicking">
                            <div class="notification" :class="notificationMessageClass">
                                {{ returnNotificationMessage }}
                            </div>
                            <div class="field" v-if="pickingInformation.success">
                                <label class="label">Nuovo Picking:</label>
                                <p class="control" v-if="pickingInformation.Area == 'ACI'">
                                    Ritira il nuovo Item nel magazzino
                                </p>
                                <p class="control" v-else>
                                    Ritira il nuovo Item nell' ubicazione {{ pickingInformation.area_name }} -
                                    {{ pickingInformation.Area }}
                                </p>
                            </div>
                            <div class="field" v-else>
                                <label class="label">Nessun prodotto disponibile per la sostituzione</label>
                                <p class="control">Errore: <br>
                                    {{ pickingInformation.error }}
                                </p>
                            </div>
                            <button v-if="pickingInformation.success" type="button" class="button"
                                    @click="closeReturnModal">Ritiro dopo
                            </button>
                            <button v-if="pickingInformation.success" type="submit"
                                    class="button is-success is-pulled-right"
                                    :class="executingReturnOrder ? 'is-loading' : ''" :disabled="executingReturnOrder">
                                Ritira adesso
                            </button>
                            <button v-if="!pickingInformation.success" type="button" class="button"
                                    @click="closeReturnModal">Chiudi
                            </button>
                        </form>
                    </section>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
const { TO_CHECK_TYPE, TO_CUSTOMIZE_TYPE, TO_WRAP_TYPE, MAX_QUANTITY_FAST_SCAN } = require('../constants.js');
import axios from 'axios';
import VueSlickCarousel from 'vue-slick-carousel';

import { mapActions, mapGetters } from 'vuex';

const Print = require('../models/Print').default;
const Item = require('../models/Item').default;

export default {
    props: ['item', 'undo', 'readonly'],
    data() {
        return {
            isExpanded: false,
            images: {
                main: null,
                rollover: null,
                item_additionals: [],
                product_additionals: [],
            },
            imagesLoaded: false,
            showReturnModal: false,
            noteModal: {
                show: false,
                body: '',
                creating: false
            },
            showReturnMessageNotification: false,
            notificationMessageClass: null,
            returnItemReason: '',
            executingReturnOrder: false,
            OverFlowTextReturn: 'notification is-pulled-right',
            pickingInformation: [],
            resetCountFocus: false,
            countFocusStarted: false,
            TIME_TO_AUTO_FOCUS: 5000, //millisecondi
        }
    },
    components: { VueSlickCarousel },

    computed: {
        ...mapGetters([
            'ordersType',
            'deleteInProgress',
            'orderId'
        ]),
        isError() {
            return this.item.check === 'error';
        },

        showFastCheck() {
            return this.item.check === 'undone' && this.item.qty > MAX_QUANTITY_FAST_SCAN;
        },

        imageSize() {
            return this.isExpanded ? 'is-340x340' : 'is-96x96';
        },

        showReturnAndPrint() {
            return this.ordersType === TO_CHECK_TYPE && this.item.check === 'undone';
        },
        showItemId() {
            return process.env.NODE_ENV === 'development';
        },
        quantity: {
            get() {
                return this.$store.state.operation.quantity;
            },
            set(quantity) {
                this.setQuantity(quantity);
            }
        }
    },
    methods: {
        ...mapActions([
            'createReturnOrder',
            'resetCommand',
            'setQuantity'
        ]),
        toggleExpand() {
            if (this.item.check === 'undone' && (this.ordersType === TO_CUSTOMIZE_TYPE || this.ordersType === TO_WRAP_TYPE)) {
                this.isExpanded = !this.isExpanded;
            }
        },

        undoAction(item) {
            item.orderId = this.orderId;
            this.undo(item);
            this.resetCommand();
        },

        printItemLabel() {
            this.resetCountFocus = this.countFocusStarted;
            Print.printItemLabel(this.item.item_id)
                .then(response => {
                    this.focustimed();
                }).catch(error => {
                this.focustimed();
            })
        },

        focustimed() {
            this.countFocusStarted = true;
            setTimeout(() => {
                if (this.resetCountFocus) {
                    this.resetCountFocus = false;
                    console.log('altri 6 secondi');
                    this.focustimed();
                } else {
                    console.log('resetcommand');
                    this.countFocusStarted = false;
                    this.resetCommand();
                }
            }, this.TIME_TO_AUTO_FOCUS);
        },

        async showGallery() {
            let { data } = await Item.getImages(this.item.item_id);

            this.images = data;
            this.imagesLoaded = true;
        },

        closeGallery() {
            this.imagesLoaded = false;
            this.images = {
                main: null,
                rollover: null,
                item_additionals: [],
                product_additionals: [],
            };
        },

        closeReturnModal() {
            if (this.executingReturnOrder) return;
            this.showReturnMessageNotification = false;
            this.showReturnModal = false;
            this.returnItemReason = '';
            window.location.reload();
        },

        closeAddNoteModal() {
            this.noteModal.show = false;
            this.noteModal.body = '';
        },
        async confirmPicking() {
            this.executingReturnOrder = true;
            axios.post(`/fulfilment/confirm-picked`, {
                itemId: this.item.item_id,
                fulfilment_order_product_id: this.item.fulfilment_order_product_id
            }).then(() => {
                this.executingReturnOrder = false;
                this.closeReturnModal();
            });
        },
        async confirmReturnItem() {
            this.executingReturnOrder = true;
            let response = await this.createReturnOrder({
                itemId: this.item.item_id,
                reason: this.returnItemReason,
                fulfilment_order_product_id: this.item.fulfilment_order_product_id
            });

            if (response.success) {
                this.returnNotificationMessage = 'Reso a fornitore inserito';
                this.notificationMessageClass = 'is-success';
                this.pickingInformation = response.pickingInformation;
                if (!this.pickingInformation.success) {
                    this.notificationMessageClass = 'is-danger';
                }
            } else {
                this.returnNotificationMessage = 'Ordine annullato';
                this.notificationMessageClass = 'is-danger';
            }

            this.showReturnMessageNotification = true;
            this.executingReturnOrder = false;
        },

        insertNote() {
            this.noteModal.creating = true;

            let body = '(' + this.item.product_name + ' ' + this.item.product_description + ' ' + this.item.item_color + ') ' + this.noteModal.body;
            this.$store.dispatch('insertNote', body)
                .then(response => {
                    this.noteModal.show = false;
                    this.noteModal.body = '';
                    this.noteModal.creating = false;
                });
        }
    },
    watch: {
        returnItemReason: function (NewVal) {
            if (NewVal.search(/[^\w\s ' . , ]/g) != -1) {
                alert('La descrizione non può contenere caratteri speciali e/o accentati');
                this.returnItemReason = NewVal.substr(0, NewVal.length - 1);
                return false;
            }
            if (NewVal.length > 55) {
                this.OverFlowTextReturn = 'notification is-pulled-right is-danger';
            } else {
                this.OverFlowTextReturn = 'notification is-pulled-right';
            }
        },
    }
}
</script>

<style scoped>
.box.done {
    box-shadow: 0px 1px 1px 0px rgba(119, 237, 92, 1),
    0 0 0 1px rgba(119, 237, 92, 1)
}

.box.error {
    box-shadow: 0px 1px 1px 0px rgba(207, 0, 0, 1),
    0 0 0 1px rgba(207, 0, 0, 1)
}

.block {
    position: relative;
    right: -10%;
}

.is-340x340 {
    width: 340px;
    height: 340px;
}

hr {
    margin: 0.5rem 0;
}

.wrap-box {
    font-size: 1.9rem;
}

.modal-background {
    background-color: rgba(10, 10, 10, 0.45);
}

.deleting-item {
    width: 25px;
    height: 25px;
}
</style>
